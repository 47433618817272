// this file contains all default routing and components settings for using as dynamic for future WC

import { inputIdsRegister } from "@/helpers/wc/inputSettings";
import { buttonTypesRegister } from "@/helpers/wc/buttonSettings";
import { imageTypesRegister } from "@/helpers/wc/imageSettings";
import { checkboxTypesRegister } from "@/helpers/wc/checkBoxSettings";
import { labelTemplatesRegister } from "@/helpers/wc/labelTemplatesSettings";

import keys from '@/helpers/wc/componentKeys';
import content from '@/helpers/wc/textContentSettings';
import componentsRegister from '@/helpers/wc/componentsRegister';

export const componentKeys = keys;

export const inputIds = inputIdsRegister;

export const contentValue = content;

export const labelTemplates = labelTemplatesRegister;

export const buttonTypes = buttonTypesRegister;
export const imageTypes = imageTypesRegister;
export const checkBoxTypes = checkboxTypesRegister;

// default components register
export const components = componentsRegister;
