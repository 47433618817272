import getErrorResponse from "@/mixins/getResponseData";
import MiddlewareService from "@/MiddlewareService";
import constants from "@/store/admin/constants/admin";
import siteModules from "@/helpers/cms/siteModules";

export default {
  mixins: [getErrorResponse, MiddlewareService],
  computed: {
    module() {
      const param = this.$route.path[this.$route.path.length - 1];
      return param === siteModules.MeetingRoom ? 'meetingroom' : '';
    }
  },
  methods: {
    /**
     * * Send request to API to get current site details
     *  @param {string|optional} id. Current site Id
     */
    async getSiteById(id = this.$route.params.serviceId, module) {
      let url = `/Sites/${id}`;
      if (module) url += `?module=${module}`;
      const data = await this.processRequest(url);
      if (data?.body?.success) {
        await this.$store.dispatch(constants.setSite, data.body.message);
      } else {
        this.$awn.alert(this.getErrorResponse(data));
      }
    },
    /**
     * * Send request to API to update site properties
     *  @param {Object} model. Site model with properties
     *  @return {Object | null} Returns site object if success or null
     */
    async saveSite(model) {
      const data = await this.processRequest("/Sites", "put", model);
      if (data?.body?.success) {
        // don't need to save into the store
        return data.body.message;
      } else {
        this.$awn.alert(this.getErrorResponse(data));
        return null;
      }
    },
    async getCompanySitesList() {
      const data = await this.processRequest(`/Sites/List?modules=${this.module}`);
      if (data.ok) {
        await this.$store.dispatch(constants.setSites, data.body);
      } else {
        this.$awn.alert(this.getErrorResponse(data));
      }
    },

    /**
     * * Send request to API to get site details for client
     * *  @param {string|optional} siteId. Current site Id
     */
    async getClientSiteDetails(siteId = this.$route.params.siteId, module = false, code = '') {
      let url = `/Sites/${siteId}/client`;
      let add = '?';
      if (module) {
        url += `${add}module=${module}`; add = '&';
      }
      if (code) {
        url += `${add}code=${code}`; add = '&';
      }
      const data = await this.processRequest(url);
      const site = data.body.message;
      if (!site) return;
      if (data.body.company) {
        this.$store.commit('token', data.body.company.token);
        localStorage.token = sessionStorage.token = data.body.company.token.bearer;
        delete data.body.company.token;
        this.$store.commit('company', data.body.company);
      }
      if (data.body.tags) this.$store.commit('actionResult', data.body.tags);
      if (site.width) {
        site.siteHeight = site.height;
        site.siteWidth = site.width;
        for (const page of site.sitePages) {
          for (const pc of page.pageComponents) {
            pc.pageComponentContents = pc.componentContents;
            pc.properties = JSON.parse(pc.properties);
            pc.styles = JSON.parse(pc.styles);
          }
        }
      }
      this.$store.commit(constants.setSite, site);
      return site;
    },
  }
};
