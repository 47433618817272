export default {
  WebConstructor: 0,
  ProconEvent: 1,
  VisitExpress: 2,
  MeetingRoom: 3,
  InfoSearch: 4,
  Queue: 5,
  Linked: 6,
  PrintTemplate: 7,

  AnyType: 15,

  ResponsiveDesign: 16
};
